import {Component, OnInit} from '@angular/core';
import {TopbarComponent} from '../../navigation/topbar/topbar.component';
import {SidenavComponent} from '../../navigation/sidenav/sidenav.component';
import {ToastModule} from 'primeng/toast';
import {RouterOutlet} from '@angular/router';
import {TopBarComponent} from "./top-bar/top-bar.component";
import {DefaultLayoutSidenavComponent} from "./sidenav/default-layout-sidenav.component";
import {TitleBarComponent} from "./title-bar/title-bar.component";

@Component({
    selector: 'app-default-layout',
    templateUrl: './default-layout.component.html',
    styleUrls: ['./default-layout.component.scss'],
    standalone: true,
  imports: [TopbarComponent, SidenavComponent, ToastModule, RouterOutlet, DefaultLayoutSidenavComponent, TopBarComponent, TitleBarComponent]
})
export class DefaultLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
