import {Component, effect, inject} from '@angular/core';
import {StyleClassModule} from "primeng/styleclass";
import {Ripple} from "primeng/ripple";
import {InputTextModule} from "primeng/inputtext";
import {ButtonModule} from "primeng/button";
import {SelectButton} from "primeng/selectbutton";
import {FormsModule} from "@angular/forms";
import {BadgeModule} from "primeng/badge";
import {RouterLink, RouterLinkActive, RouterOutlet} from "@angular/router";
import {NgClass, NgOptimizedImage} from "@angular/common";
import {TopBarComponent} from "../top-bar/top-bar.component";
import {TranslateModule} from "@ngx-translate/core";
import {injectQuery} from "@tanstack/angular-query-experimental";
import {BranchesService} from "../../../shared/services/branches.service";

@Component({
  selector: 'app-default-layout-sidenav',
  standalone: true,
  imports: [
    StyleClassModule,
    Ripple,
    InputTextModule,
    ButtonModule,
    SelectButton,
    FormsModule,
    BadgeModule,
    RouterOutlet,
    NgOptimizedImage,
    TopBarComponent,
    RouterLink,
    RouterLinkActive,
    NgClass,
    TranslateModule
  ],
  templateUrl: './default-layout-sidenav.component.html',
  styleUrl: './default-layout-sidenav.component.scss'
})
export class DefaultLayoutSidenavComponent {

  menuItems: {
    title: string
    link: string
    items: {
      icon: string
      label: string
      link: string
    }[]
  }[] = [
    {
      title: 'EVENTS',
      link: 'events/',
      items: [{
        icon: 'pi pi-calendar',
        label: 'OVERVIEW',
        link: 'list'
      }, {
        icon: 'pi pi-calendar-clock',
        label: 'TEMPLATES',
        link: 'templates'
      }, {
        icon: 'pi pi-folder',
        label: 'ARCHIVE',
        link: 'archive'
      }, {
        icon: 'pi pi-map-marker',
        label: 'LOCATIONS',
        link: 'locations'
      }, {
        label: 'EMPLOYEE_REQUESTS',
        icon: 'pi pi-user-plus',
        link: 'request/employee'
      }, {
        label: 'EMPLOYEE_CANCEL',
        link: 'employee/cancel',
        icon: 'pi pi-user-minus'
      }, {
        icon: 'pi pi-question',
        label: 'HELP_REQUESTS',
        link: 'request/help'
      }]
    }, {
      title: 'ASSIGNMENT',
      link: 'assignment/branch/',
      items: []
    }, {
      title: 'EMPLOYEES',
      link: 'employees/',
      items: [{
        icon: 'pi pi-user',
        label: 'OVERVIEW',
        link: ''
      }, {
        icon: 'pi pi-users',
        label: 'TEAMS',
        link: 'teams'
      }, {
        icon: 'pi pi-file',
        label: 'DOCUMENTS',
        link: 'documents'
      }]
    }, {
      title: 'CUSTOMERS',
      link: 'customers/',
      items: [{
        icon: 'pi pi-users',
        label: 'OVERVIEW',
        link: 'list'
      }, {
        icon: 'pi pi-question',
        label: 'REQUEST',
        link: 'request'
      }]
    }
  ];
  private branchesService = inject(BranchesService)
  branchesQuery = injectQuery(() => ({
    queryFn: () => this.branchesService.getBranches(),
    queryKey: ['branches']
  }))

  constructor() {

    effect(() => {
      const data = this.branchesQuery.data();
      data?.forEach(branch => {
        this.menuItems.find(item => item.title === 'ASSIGNMENT')?.items.push(
          {
            icon: 'pi pi-home',
            link: branch.id,
            label: branch.name
          }
        )
      })
    })
  }
}
