<div class="bg-surface-0 z-10 flex justify-end items-center h-[70px] m-auto pr-4 lg:fixed top-0 right-0 left-[250px]">
  <a [hideOnOutsideClick]="true" class="cursor-pointer inline-flex items-center justify-center lg:hidden text-surface-700" enterActiveClass="animate-fadeinleft" enterFromClass="hidden" leaveActiveClass="animate-fadeoutleft" leaveToClass="hidden" pRipple pStyleClass="#app-sidebar-12">
    <i class="pi pi-bars text-4xl"></i>
  </a>
  <a [hideOnOutsideClick]="true" class="cursor-pointer block lg:hidden text-surface-700" enterFromClass="hidden" leaveToClass="hidden" pRipple pStyleClass="@next">
    <i class="pi pi-ellipsis-v text-2xl"></i>
  </a>
  <ul class="list-none p-4 lg:p-0 m-0 lg:items-center select-none flex-col lg:flex-row absolute hidden lg:flex lg:static right-0 top-full z-10 shadow lg:shadow-none">
    <li class="lg:mr-1">
      <a href="https://my.covento.de" target="_blank">
        My Covento
      </a>
    </li>
    <li class="lg:mr-1">
      <a href="https://plattform.covento.de" target="_blank">
        Plattform
      </a>
    </li>
    <li class="lg:mr-1">
      <a class="flex p-4 items-center text-surface-600 hover:text-surface-900 hover:bg-surface-100 font-medium rounded-border cursor-pointer transition-colors duration-150 lg:rounded-full" pRipple>
        <i class="pi pi-bell text-base lg:text-2xl mr-2 lg:mr-0" pBadge severity="danger"></i>
        <span class="block lg:hidden font-medium">Notifications</span>
      </a>
    </li>
    <li class="border-t border-surface lg:border-t-0 lg:mt-0 mt-2 lg:pt-0 pt-2">
      <a *ngIf="me$| async as user" class="flex p-4 items-center hover:bg-surface-100 font-medium rounded-border cursor-pointer transition-colors duration-150 lg:rounded-full" pRipple>
        <p-avatar>{{user.firstName.at(0)}}{{user.lastName.at(0)}}</p-avatar>
        <div class="ml-2">
          <div class="text-surface-900 font-medium">{{user.firstName}} {{user.lastName}}</div>
        </div>
      </a>
    </li>
  </ul>
</div>
