import {NgModule} from '@angular/core';
import {TopbarComponent} from './topbar/topbar.component';
import {SidenavComponent} from './sidenav/sidenav.component';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../shared/shared.module';


@NgModule({
    exports: [
        TopbarComponent,
        SidenavComponent
    ],
    imports: [
        SharedModule,
        RouterModule,
        TopbarComponent,
        SidenavComponent
    ]
})
export class NavigationModule { }
