import {Component, inject, OnInit} from '@angular/core';
import {NgClass, NgIf} from "@angular/common";
import {StyleClassModule} from "primeng/styleclass";
import {BadgeModule} from "primeng/badge";
import {AuthService} from "../../../auth/auth.service";
import {map} from "rxjs/operators";
import {Observable} from "rxjs";
import {Notification, User} from "../../../../generated/graphql";
import {AvatarModule} from "primeng/avatar";
import {Ripple} from "primeng/ripple";
import {NotificationItemComponent} from "../../../shared/notification-item/notification-item.component";
import {SharedService} from "../../../shared/shared.service";
import {MenubarModule} from "primeng/menubar";
import {MenuItem} from "primeng/api";
import {Router} from "@angular/router";

@Component({
  selector: 'app-top-bar',
  standalone: true,
  imports: [
    StyleClassModule,
    BadgeModule,
    NgIf,
    AvatarModule,
    Ripple,
    NotificationItemComponent,
    MenubarModule,
    NgClass
  ],
  templateUrl: './top-bar.component.html',
  styleUrl: './top-bar.component.scss'
})
export class TopBarComponent implements OnInit {

  notifications: any;
  loading = false;
  items: MenuItem[] = [
    {
      id: 'myCovento',
      label: 'My Covento',
      href: 'https://my.covento.de'
    },
    {
      id: 'platform',
      label: 'Plattform',
      href: 'https://plattform.covento.de'
    },
    {
      id: 'notifications',
      badge: '0',
      items: []
    },
    {
      id: 'user',
      initials: null,
      items: []
    }
  ];
  private authService = inject(AuthService);
  me$: Observable<User> = this.authService.me().pipe(map(me => me?.data?.me as User));
  private router = inject(Router)
  private sharedService = inject(SharedService);

  ngOnInit(): void {
    this.loading = true;
    this.authService.me().subscribe((me) => {
      this.sharedService.getNotifications().subscribe(({data}) => {
        const copy = [...this.items];
        let notiItem = copy.find(i => i.id === 'notifications')
        notiItem!.badge = data.notifications.length.toString();
        notiItem!.items = data.notifications.map(noti => {
          return {
            id: noti.id,
            title: noti.title,
            message: noti.text,
            notification: noti
          }
        })
        let userItem = copy.find(i => i.id === 'user')
        userItem!.label = me.data.me.firstName + ' ' + me.data.me.lastName
        userItem!['initials'] = me.data.me.firstName.at(0)! + me.data.me.lastName.at(0)
        userItem!.items = [{
          label: me.data.me.email,
        }]
        userItem!.items.push({
          label: 'Logoout',
          command: () => this.onLogout()
        })
        this.notifications = [...data.notifications as Notification[]]
        this.items = [...copy]
        this.loading = false;
      })
    })
  }

  onLogout() {
    this.authService.logout();
    localStorage.removeItem('token');
    this.router.navigate(['/auth/login'])
  }

  readNotifications(notifications: Notification []) {
    this.sharedService.markNotification({ids: notifications.map((value => value.id))}).subscribe(({data}) => {
      console.log(data)
    })
  }
}
