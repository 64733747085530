import {Component, inject} from '@angular/core';
import {AsyncPipe, NgIf, NgOptimizedImage} from "@angular/common";
import {StyleClassModule} from "primeng/styleclass";
import {BadgeModule} from "primeng/badge";
import {AuthService} from "../../../auth/auth.service";
import {map} from "rxjs/operators";
import {Observable} from "rxjs";
import {User} from "../../../../generated/graphql";
import {AvatarModule} from "primeng/avatar";
import {Ripple} from "primeng/ripple";

@Component({
  selector: 'app-top-bar',
  standalone: true,
  imports: [
    NgOptimizedImage,
    StyleClassModule,
    BadgeModule,
    NgIf,
    AsyncPipe,
    AvatarModule,
    Ripple
  ],
  templateUrl: './top-bar.component.html',
  styleUrl: './top-bar.component.scss'
})
export class TopBarComponent {

  private authService = inject(AuthService);
  me$: Observable<User> = this.authService.me().pipe(map(me => me?.data?.me as User));
}
