import { Component, OnInit } from '@angular/core';
import {NavigationModel, NavsGQL, NavsQuery} from '../../../generated/graphql';
import {Observable} from "rxjs";
import {ApolloQueryResult} from "@apollo/client";
import { RouterLink, RouterLinkActive } from '@angular/router';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { BadgeModule } from 'primeng/badge';

@Component({
    selector: 'app-sidenav',
    templateUrl: './sidenav.component.html',
    styleUrls: ['./sidenav.component.scss'],
    standalone: true,
    imports: [RouterLink, NgIf, NgFor, RouterLinkActive, BadgeModule, AsyncPipe]
})
export class SidenavComponent implements OnInit {

  items$: Observable<ApolloQueryResult<NavsQuery>> = new Observable<ApolloQueryResult<NavsQuery>>();
  constructor(
    private navsGQL: NavsGQL
  ) { }

  hasItems(item: any) {
    return item.subItems !== undefined ? item.subItems.length > 0 : false;
  }
  ngOnInit(): void {
    this.items$ = this.navsGQL.watch().valueChanges;
  }

}
