<div class="bg-surface-0 z-10 flex justify-end items-center h-[70px] m-auto pr-4 lg:fixed top-0 right-0 left-[250px]">
  @if (!loading) {
    <p-menubar [model]="items">
      <ng-template let-item let-root="root" pTemplate="item">
        <div class="flex items-center p-menubar-item-link" pRipple>
          <i *ngIf="item.id === 'notifications'" [ngClass]="['ml-auto pi', 'pi pi-bell']"></i>
          <p-avatar *ngIf="item.id === 'user'">{{ item.initials }}</p-avatar>
          <span *ngIf="item.label && !item.href" class="ml-2">{{ item.label }}</span>
          <a *ngIf="item.href" [href]="item.href" target="_blank">{{ item.label }}</a>
          <p-badge *ngIf="item.badge" [ngClass]="{ 'ml-auto': !root, 'ml-1': root }" [value]="item.badge"/>
          @if (!root) {
            <app-notification-item [notification]="item.notification"></app-notification-item>
          }
        </div>
      </ng-template>
    </p-menubar>
  }
</div>
