<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <!--      <button type="button" class="btn btn-sm px-4 font-size-16 header-item vertical-menu-btn" id="vertical-menu-btn">-->
      <!--        <i class="fa fa-fw fa-bars"></i>-->
      <!--      </button>-->
    </div>


    <div class="d-flex items-center">
      <div class="px-4">
        <i class="pi pi-th-large text-lg text-gray-700"></i>
        <a [href]="platformUrl" class="text-lg font-medium ml-2 text-gray-700" target="_blank">Covento Plattform</a>
      </div>
      <div class="px-4">
        <i class="pi pi-th-large text-lg text-gray-700"></i>
        <a [href]="myCoventoUrl" class="text-lg font-medium ml-2 text-gray-700" target="_blank">My Covento</a>
      </div>
      <div ngbDropdown>
        <button class="p-button-rounded p-button-text" ngbDropdownToggle pButton type="button">
          <i *ngIf="notifications" [value]="notifications.length.toString()" class="pi pi-bell" pBadge></i>
        </button>
        <div aria-labelledby="dropdownBasic1" class="scroller_notification" ngbDropdownMenu>
          <div class="p-4">
            <div class="flex flex-row justify-between items-center">
              <h5 class="m-0 font-size-16">Benachrichtigungen</h5>
              <p class="mb-0"><a (click)="readNotifications(notifications)" class="small font-normal" type="button">Alle als gelesen markieren</a></p>
            </div>
            <hr />
            <div *ngFor="let notification of notifications">
              <app-notification-item [notification]="notification"></app-notification-item>
            </div>
          </div>
        </div>
      </div>
      <div class="mx-auto my-auto pr-12" ngbDropdown>
        <button class="p-button-rounded p-button-text" ngbDropdownToggle pButton type="button">
<!--          <p-avatar *ngIf="!loading" [label]="me?.firstName?.charAt(0) +  me?.lastName?.charAt(0)" [style]="{'background-color': '#817CCD', 'color': '#ffffff'}"-->
<!--                    shape="circle" size="xlarge"></p-avatar>-->
          <span *ngIf="!loading" class="ml-2 text-dark">{{me?.firstName}}</span>
          <i class="mdi mdi-chevron-down font-size-20 mb-0 text-dark"></i>
        </button>
        <div aria-labelledby="dropdownBasic1" class="px-2" ngbDropdownMenu>
          <div class="px-4 flex flex-row justify-start items-center">
<!--            <p-avatar *ngIf="!loading" [label]="me?.firstName?.charAt(0) +  me?.lastName?.charAt(0)" [style]="{'background-color': '#817CCD', 'color': '#ffffff'}"-->
<!--                      shape="circle" size="xlarge"></p-avatar>-->
            <div class="flex flex-col mt-2">
              <span *ngIf="!loading" class="ml-2 text-dark text-lg font-medium">{{me?.firstName}} {{me?.lastName}}</span>
              <span *ngIf="!loading" class="ml-2 text-gray-700 text-sm">{{me?.email}}</span>
            </div>
          </div>
          <hr />
          <ul class="list-none px-4">
            <li (click)="onLogOut()" class="mt-2 mb-2" style="cursor : pointer">
              <i class="mdi mdi-exit-to-app text-lg text-primary"></i>
              <span class="text-lg ml-1">Logout</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</header>
