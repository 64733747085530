<div
  class="bg-surface-0 hidden lg:block fixed left-0 top-0 z-10 select-none shadow-lg w-[260px] h-screen overflow-y-auto"
  id="app-sidebar-12">
  <div class="flex flex-col">
    <div class="p-6 flex items-center justify-center">
      <img alt="Logo" class="h-[60px]" src="assets/images/logo.svg">
    </div>
    <div>
      <ul class="list-none px-4 pb-4 pt-2 m-0">
        @for (item of menuItems; track $index) {
          <li>
            <div
              class="p-4 flex items-center justify-between text-surface-600 border-l-4 border-primary-500 bg-primary-200 rounded-md my-1 shadow-sm">
              <span class="font-medium text-sm text-primary-900">{{ item.title | translate }}</span>
            </div>
            <ul class="list-none p-0 m-0 ml-4 mt-2">
              @for (child of item.items; track $index) {
                <li class="my-1">
                  <a [routerLink]="item.link + child.link"
                     routerLinkActive="border-primary border-l-4 bg-primary-50 text-primary-600"
                     class="flex items-center cursor-pointer p-3 text-surface-700 hover:bg-surface-100 rounded-md transition-all duration-200 ease-in-out shadow-sm"
                     pRipple>
                    <i class="mr-2 text-primary-500" [ngClass]="child.icon"></i>
                    <span class="font-medium text-surface-900">{{ child.label | translate }}</span>
                    <p-badge *ngIf="($requests|async) && child.label === 'EMPLOYEE_REQUESTS'"
                             class="mx-auto my-auto ms-2" [value]="$requests|async"
                             severity="primary"></p-badge>
                    <p-badge *ngIf="($cancel|async) && child.label === 'EMPLOYEE_CANCEL'"
                             class="mx-auto my-auto ms-2" [value]="($cancel|async)"
                             severity="primary"></p-badge>
                  </a>
                </li>
              }
            </ul>
          </li>
        }
      </ul>
    </div>
  </div>
</div>
