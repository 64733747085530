import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './auth/auth.guard';
import {AuthLayoutComponent} from "./layouts/auth-layout/auth-layout.component";
import {DefaultLayoutComponent} from "./layouts/default-layout/default-layout.component";


const AUTH_ROUTES: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
];
const DASHBOARD_ROUTES: Routes = [
  {
    path: 'employees',
    canActivate: [AuthGuard],
    loadChildren: () => import('./employee/employee.module').then(m => m.EmployeeModule),
  },
  {
    path: 'assignment',
    canActivate: [AuthGuard],
    loadChildren: () => import('./assignment/assignment.module').then(m => m.AssignmentModule),
  },
  {
    path: 'customer',
    canActivate: [AuthGuard],
    loadChildren: () => import('./customer/customer.module').then(m => m.CustomerModule)
  }
];

const routes: Routes = [
  { path: '', redirectTo: 'events', pathMatch: 'full', },
  { path: '', component: AuthLayoutComponent, children: AUTH_ROUTES },
  { path: '', component: DefaultLayoutComponent, canActivate: [AuthGuard], children: DASHBOARD_ROUTES }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
