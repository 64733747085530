<ul class="list-none py-4 px-8 m-0 bg-surface-0 flex items-center font-medium overflow-x-auto border-t border-b border-surface">
  <li class="pr-4">
    <a class="cursor-pointer">
      <i class="pi pi-home text-surface-600 dark:text-surface-200"></i>
    </a>
  </li>
  <li class="px-2">
    <i class="pi pi-angle-right text-surface-600 dark:text-surface-200"></i>
  </li>
  <li class="px-2">
    <a class="cursor-pointer text-surface-600 dark:text-surface-200 whitespace-nowrap">Favorites</a>
  </li>
  <li class="px-2">
    <i class="pi pi-angle-right text-surface-600 dark:text-surface-200"></i>
  </li>
  <li class="px-2">
    <a class="cursor-pointer text-primary whitespace-nowrap">Dashboard</a>
  </li>
</ul>
