import {Routes} from '@angular/router';
import {DefaultLayoutComponent} from "./layouts/default-layout/default-layout.component";
import {AuthGuard} from "./auth/auth.guard";


const appRoutes: Routes = [
  {
    path: 'events',
    loadChildren: () => import('./features/event/event.routes').then(mod => mod.routes)
  },
  // {
  //   path: 'employees',
  //   loadChildren: () => import('./features/employee/employee.routes').then(mod => mod.routes)
  // },
  // {
  //   path: 'assignment',
  //   loadChildren: () => import('./features/assignment/assignment.routes').then(mod => mod.routes)
  // },
  // {
  //   path: 'locations',
  //   loadChildren: () => import('./features/locations/location.routes').then(mod => mod.routes)
  // },
];

export const routes: Routes = [
  // { path: '', component: AuthLayoutComponent, children: AUTH_ROUTES },
  { path: '', component: DefaultLayoutComponent, canActivate: [AuthGuard], children: appRoutes }
]


