<div class="vertical-menu scrollNav">
  <!-- LOGO -->
  <div class="navbar-brand-box">
    <a routerLink="/" class="logo logo-dark">
            <span class="logo-sm">
                <img src="/assets/images/logo.svg" alt height="60" />
            </span>
      <span class="logo-lg">
                 <img src="/assets/images/logo.svg" alt height="60" />
            </span>
    </a>

    <a routerLink="" class="logo logo-light">
            <span class="logo-sm">
                <img src="/assets/images/logo.svg" alt height="60" />
            </span>
      <span class="logo-lg">
                 <img src="/assets/images/logo.svg" alt height="60" />
            </span>
    </a>
  </div>

  <button type="button" class="btn btn-sm px-4 font-size-16 header-item vertical-menu-btn">
    <i class="fa fa-fw fa-bars"></i>
  </button>

  <div data-simplebar class="sidebar-menu-scroll">
    <!--- Sidemenu -->
    <div id="sidebar-menu">
      <!-- Left Menu Start -->
      <ul class="metismenu list-unstyled" id="side-menu"  *ngIf="(items$|async)?.data?.navigations as items">
        <ng-container *ngFor="let item of items">
          <li class="menu-title bg-primary text-primary-contrast bg-opacity-25 text-primary mb-2 mt-2" *ngIf="item.isTitle">{{ item.label }}</li>
          <li *ngIf="!item.isTitle">
            <a [routerLink]="item.link" [routerLinkActive]="'bg-secondary bg-opacity-25'" *ngIf="!hasItems(item)" class="side-nav-link-ref">
              <i [class]="item.icon + ' font-size-18'" *ngIf="item.icon"></i>
              <span>{{ (item.label) }}</span>
              <p-badge *ngIf="item.badge" class="mx-auto my-auto ms-2" [value]="item.badge"  severity="primary"></p-badge>
<!--              <span v-if="item.id === 999" class="badge bg-primary text-primary-contrast rounded-pill">{{countEventRequests}}</span>-->
<!--              <span v-if="item.id === 998" class="badge bg-primary text-primary-contrast rounded-pill">{{countCanceledShifts}}</span>-->
<!--              <span v-if="item.id === 100001" class="badge bg-primary text-primary-contrast rounded-pill">{{countEventHelpRequests}}</span>-->
            </a>

<!--            <ul v-if="hasItems(item)" class="sub-menu" aria-expanded="false">-->
<!--              <li v-for="(subitem, index) of item.subItems" :key="index">-->
<!--                <nuxt-link :to="subitem.link" v-if="!hasItems(subitem)" class="side-nav-link-ref">{{ $t(subitem.label) }}</nuxt-link>-->
<!--                <a v-if="hasItems(subitem)" class="side-nav-link-a-ref has-arrow" href="javascript:void(0);">{{ $t(subitem.label) }}</a>-->
<!--                <ul v-if="hasItems(subitem)" class="sub-menu mm-collapse" aria-expanded="false">-->
<!--                  <li v-for="(subSubitem, index) of subitem.subItems" :key="index">-->
<!--                    <nuxt-link :to="subSubitem.link" class="side-nav-link-ref">{{ $t(subSubitem.label) }}</nuxt-link>-->
<!--                  </li>-->
<!--                </ul>-->
<!--              </li>-->
<!--            </ul>-->
          </li>
        </ng-container>
      </ul>
    </div>
    <!-- Sidebar -->
  </div>
</div>
