import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpInterceptorFn, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token = localStorage.getItem('token');

    if (token) {
      // If we have a token, we set it to the header
      request = request.clone({
        setHeaders: {Authorization: `Bearer ${token}`}
      });
    }
    return next.handle(request);
  }
}


export const authInterceptor: HttpInterceptorFn = (req, next) => {
      return next(req.clone({
        setHeaders: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      })
  )
};




// import {HttpInterceptorFn} from '@angular/common/http';
// import {inject} from "@angular/core";
// import {KindeAngularService} from "kinde-angular";
// import {exhaustMap, first} from "rxjs";
//
// export const authInterceptor: HttpInterceptorFn = (req, next) => {
//   const kindeService = inject(KindeAngularService)
//   return kindeService.accessToken$.pipe(
//     first(),
//     exhaustMap(token => {
//       return next(req.clone({
//         setHeaders: {
//           Authorization: `Bearer ${token}`,
//         }
//       }))
//     })
//   )
// };

