import {ApplicationConfig, importProvidersFrom, provideZoneChangeDetection} from '@angular/core';
import {provideRouter, RouterModule, withComponentInputBinding} from '@angular/router';

import {routes} from './app.routes';
import {HttpClient, provideHttpClient, withInterceptors} from '@angular/common/http';
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {BrowserModule} from "@angular/platform-browser";
import {AppRoutingModule} from "./app-routing.module";
import {ToastModule} from "primeng/toast";
import {NavigationModule} from "./navigation/navigation.module";
import {GraphQLModule} from "./graphql.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {authInterceptor} from "./auth/auth.interceptor";
import {provideAnimationsAsync} from "@angular/platform-browser/animations/async";
import {provideTanStackQuery, QueryClient} from "@tanstack/angular-query-experimental";
import {BrowserAnimationsModule, provideNoopAnimations} from "@angular/platform-browser/animations";

function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export const provideTranslation = () => ({
  loader: {
    provide: TranslateLoader,
    useFactory: HttpLoaderFactory,
    deps: [HttpClient],
  },
});
export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimationsAsync(),
    // MessageService,
    // DialogService,
    // provideKinde({
    //   clientId: '92d209a76f894dfaba907ef9d155fbb3',
    //   authDomain: 'https://covento2.kinde.com',
    //   redirectURL: environment.redirectUrl,
    //   logoutRedirectURL: environment.redirectUrl
    // }),
    importProvidersFrom([
      BrowserModule, BrowserAnimationsModule, RouterModule, AppRoutingModule, ToastModule, NavigationModule, GraphQLModule, FormsModule, ReactiveFormsModule,
      TranslateModule.forRoot(provideTranslation()),
    ]),
    provideNoopAnimations(),
    provideZoneChangeDetection({eventCoalescing: true}), provideRouter(routes, withComponentInputBinding()),
    provideHttpClient(
      withInterceptors([authInterceptor])
    ),
    provideTanStackQuery(new QueryClient())
  ]
};


// bootstrapApplication(AppComponent, {
//   providers: [
//     importProvidersFrom(BrowserModule, RouterModule, AppRoutingModule, ToastModule, TranslateModule.forRoot({
//         defaultLanguage: 'de',
//         loader: {
//           provide: TranslateLoader,
//           useFactory: (createTranslateLoader),
//           deps: [HttpClient]
//         }
//       }),
//       // AgmCoreModule.forRoot({
//       //     apiKey: 'AIzaSyBeQM88i-pFYwbnuLl-Kui7PKdzfA5hBmM',
//       //     libraries: ['places']
//       // }),
//       // MatGoogleMapsAutocompleteModule,
//       NavigationModule, GraphQLModule, NgbModule, FormsModule, ReactiveFormsModule),
//     {
//       provide: HTTP_INTERCEPTORS,
//       useClass: AuthInterceptor,
//       multi: true
//     },
//     provideHttpClient(withInterceptorsFromDi()),
//     provideAnimations()
//   ]
// })
//   .catch(err => console.error(err));
